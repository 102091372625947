import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import SEO from '../../components/seo'

const ResourcesPage = () => {
  const data = useStaticQuery(graphql`
    query ResourcesVideos {
      videoItems: allMarkdownRemark(
        sort: {fields: fields___parent___name},
        filter: { fields: { parent: { relativeDirectory: { eq: "resources/videos"}, name: {ne: "index"}}}}
      ) {
        nodes {
          html
          frontmatter {
            video
          }
        }
      }
    }
  
  `)

  const { videoItems } = data

  return (
    <>
      <SEO title="Resources" />

      <section className="products-content">
        <h1>Videos</h1>

        <div className="video-grid">
          {
            videoItems.nodes.map((item, index) => {
              return (
                <div
                  key={index}
                  className="video-grid__item"
                >
                  <iframe title={`iframe-${index}`} src={item.frontmatter.video} frameBorder="0" allow="autoplay; encrypted-media;" allowFullScreen></iframe>
                  <div dangerouslySetInnerHTML={{ __html: item.html }} />
                </div>
              )
            })
          }
        </div>
      </section>

    </>
  )
}

export default ResourcesPage